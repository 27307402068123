<template>
    <q-page class="main">
        <div class="text-center m-0 vh-50 d-flex flex-column justify-content-center text-dark">
            <img class="mx-auto mb-4" width="100px" src= "favicon.png" alt = "iSLA" />
            <p v-if="language.current=='en'">
                iSLA is an interactive online version of the Slovenian Linguistic Atlas (SLA), 
                which provides free access to cartographic, visual and audio-dialectological material and at the same time enables crowd sourcing, 
                through which users can add points – local dialects and dialect recordings of individual words.
            </p>
            <p v-else>
                iSLA je interaktivna spletna različica Slovenskega lingvističnega atlasa (SLA), 
                ki omogoča prost dostop do kartografskega, slikovnega in zvočnega dialektološkega gradiva, hkrati pa omogoča tudi množičenje podatkov, 
                preko katerega uporabniki lahko dodajajo točke - krajevne govore in narečne zapise posameznih besed.
            </p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto mt-5" width="200px" src= "Logotip ZRC SAZU.png" alt = "ZRC SAZU" />
            <p class="kreon">Inštitut za slovenski jezik Frana Ramovša</p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto" height="80px" src= "aris-en.png" alt = "ARIS"  v-if="language.current=='en'" />
            <img class="mx-auto" height="80px" src= "aris-sl.png" alt = "ARIS"  v-else />
            <p class="kreon" v-if="language.current=='en'">
                This application has been created within the framework of the programme Slovenian language in synchronous and diachronic development (P6-0038, 1. 1. 2022–31. 12. 2027), and the project i-SLA: interactive atlas of Slovenian dialects (L6-2628 , September 1, 2020–August 31, 2024), which are co-financed by ARIS.
            </p>
            <p class="kreon" v-else>
                Aplikacija je nastala v okviru programa Slovenski jezik v sinhronem in diahronem razvoju (P6-0038, 1. 1. 2022–31. 12. 2027) in projekta i-SLA – Interaktivni atlas slovenskih narečij (L6-2628, 1. 9. 2020–31. 8. 2024), ki ju sofinancira ARIS.
            </p>
            <q-separator size="1rem" color="white"/>
            <img class="mx-auto" height="50px" src= "rubin_logo_z_obrobo.jpg" alt = "Rubin geoinformatika d.o.o."/>
            <p class="kreon">
                &copy; {{ year }} - Rubin geoinformatika d.o.o.
            </p>

            <q-separator />
            <!-- h5>{{ $gettext("Version") }}</h5>
            <q-separator />
            <h5>GDPR</h5 -->
            <q-separator />
        </div>
        <div class="text-justify m-0 vh-50 d-flex flex-column justify-content-center text-dark">
            <p>&nbsp;</p>
            <p><strong>Politika zasebnosti</strong></p>
            <p>&nbsp;</p>
            <p>Ta politika zasebnosti ureja zbiranje, hrambo in obdelavo osebnih podatkov, ki jih ZRC SAZU zbira od vas, ko se registrirate za uporabo spletnega portala <strong>iSLA</strong>.</p>
            <p><strong>Upravljavec</strong></p>
            <p>Upravljavec osebnih podatkov, kot ga določata Splo&scaron;na uredba EU o varstvu osebnih podatkov in veljavni zakon, ki ureja varstvo osebnih podatkov, je:</p>
            <p><strong>Znanstvenoraziskovalni center Slovenske akademije znanosti in umetnosti</strong><br /> Novi trg 2, 1000 Ljubljana <br /> Telefon: +386 1 470 61 00 <br /> E-naslov: <a href="mailto:zrc@zrc-sazu.si">zrc@zrc-sazu.si</a></p>
            <p><strong>Poobla&scaron;čena oseba za varstvo podatkov</strong></p>
            <p>Poobla&scaron;čena oseba za varstvo podatkov (DPO) in za vsa vpra&scaron;anja v zvezi z obdelavo osebnih podatkov v okviru portala iSLA je Ivan Gale. Vsa vpra&scaron;anja in poizvedbe, vključno z morebitnimi zahtevki za uveljavljanje svojih pravic v zvezi z osebnimi podatki ji lahko po&scaron;ljete:</p>
            <ul>
            <li>po e-po&scaron;ti na naslov dpo@zrc-sazu.si;</li>
            <li>po po&scaron;ti na naslov upravljavca: ZRC SAZU, Novi trg 2, 1000 Ljubljana, s pripisom &raquo;za DPO &ndash; iSLA&laquo;.</li>
            </ul>
            <p><strong>Osebni podatki in namen njihove obdelave</strong></p>
            <p>Če želite uporabljati spletni portal iSLA kot registrirani uporabnik, morate izpolniti obrazec za vpis na spletni strani https://isla.zrc-sazu.si. Z izpolnitvijo tega obrazca za vpis in njegovo potrditvijo posredujete ZRC SAZU naslednje kontaktne podatke:</p>
            <ul>
            <li><strong>e-naslov,</strong></li>
            <li><strong>ime in priimek,</strong></li>
            <li><strong>uporabni&scaron;ko ime.</strong></li>
            </ul>
            <p>Kontaktne podatke, ki nam jih posredujete ob izpolnitvi oz. oddaji obrazca za registracijo, potrebujemo za prilagoditev vsebin glede na va&scaron;e nastavitve.</p>
            <p>Če nam ne posredujete navedenih kontaktnih podatkov, va&scaron;e registracije ne bomo obdelali in ne boste mogli uporabljati vseh funkcionalnosti, ki jih ponuja portal iSLA. ZRC SAZU spo&scaron;tuje va&scaron;o zasebnost in je zavezan, da pri zbiranju, hrambi in obdelavi osebnih podatkov ravna skrbno in v skladu z veljavnimi predpisi o varstvu osebnih podatkov. Da bi preprečili nepoobla&scaron;čen dostop do pridobljenih podatkov ali njihovo razkritje, ohranili natančnost osebnih podatkov in zagotovili njihovo ustrezno uporabo, uporabljamo ustrezne tehnične in organizacijske postopke za zavarovanje podatkov, ki jih zbiramo.</p>
            <p><strong>Pravna podlaga za obdelavo &ndash; soglasje</strong></p>
            <p>Osebne podatke, ki nam jih posredujete ob registraciji na portal iSLA, ZRC SAZU obdeluje z va&scaron;im jasnim in nedvoumnim soglasjem, ki temelji na členu 6 (1), (a) Splo&scaron;ne uredbe EU o varstvu osebnih podatkov (GDPR).</p>
            <p>Soglasje za obdelavo osebnih podatkov podate s tem, ko na spletni strani ZRC SAZU na povezavi <a href="https://isla.zrc-sazu.si">https://isla.zrc-sazu.si</a>, v zavihku Vpis, izpolnite zahtevane podatke in kliknete &raquo;Potrdi&laquo;.</p>
            <p>Svoje soglasje lahko kadarkoli umaknete, tako da izbri&scaron;ete svoj račun, kar lahko storite tako, da kliknete na Izbris v zavihku Uporabnik. V primeru umika soglasja ter ukinitve profila ne bomo več uporabljali va&scaron;ih osebnih podatkov. Morebiten umik soglasja ne vpliva na zakonitost obdelave va&scaron;ih osebnih podatkov v času, preden ste umaknili soglasje.</p>
            <p><strong>Va&scaron;e pravice</strong></p>
            <p>V skladu z določili Splo&scaron;ne uredbe EU o varstvu osebnih podatkov (GDPR) imate pravico do dostopa do svojih osebnih podatkov, pravico do popravka, pravico do izbrisa (&raquo;pozabe&laquo;), pravico do prenosljivosti, pravico zahtevati omejitev obdelave osebnih podatkov in pravico do ugovora. Svoje podatke lahko kadarkoli pregledate ter spremenite s klikom na povezavo &raquo;Uredi račun&laquo;.</p>
            <p>Pri uveljavljanju vseh svojih pravic ali pri pridobivanju dodatnih informacij se obrnite na na&scaron;o poobla&scaron;čeno osebo za varstvo podatkov, ki vam je na voljo na e-naslovu: dpo@zrc-sazu.si. Va&scaron;o vlogo bomo obravnavali in nanjo odgovorili v skladu z GDPR.</p>
            <p>Če menite, da so kr&scaron;ene va&scaron;e pravice ali predpisi o varstvu osebnih podatkov, se lahko pritožite pristojnemu državnemu organu.</p>
            <p>Pritožbo lahko podate Informacijskemu poobla&scaron;čencu (Dunajska cesta 22, 1000 Ljubljana, e-naslov: gp.ip@ip-rs.si telefon: 012309730, spletna stran: www.ip-rs.si )</p>
            <p><strong>Čas hrambe osebnih podatkov</strong></p>
            <p>ZRC SAZU hrani va&scaron;e osebne podatke od registracije do ukinitve računa.</p>
            <p><strong>Uporabniki osebnih podatkov</strong></p>
            <p>Pogodbeni obdelovalec, ki za ZRC SAZU vzdržuje in gostuje spletno stran.</p>
            <p><strong>Prenos osebnih podatkov v tretjo državo ali mednarodno organizacijo</strong></p>
            <p>Osebni podatki se ne prena&scaron;ajo v tretje države ali mednarodne organizacije.</p>
            <p><strong>Pi&scaron;kotki</strong></p>
            <p>Stran uporablja pi&scaron;kotke samo za hitrej&scaron;e in optimalnej&scaron;e delovanje in ne beleži nobenih va&scaron;ih podatkov. Če tega ne želite, lahko v svojem brskalniku pi&scaron;kotke blokirate. Stran bo vseeno delovala, vendar nekoliko manj optimalno.</p>

            
            <q-separator />
            <!-- h5>{{ $gettext("Version") }}</h5>
            <q-separator />
            <h5>GDPR</h5 -->
            <q-separator />
        </div>
    </q-page>
</template>

<!--
                <div class="text-center m-0 vh-50 d-flex flex-column justify-content-center text-dark">
                    <img class="mx-auto mb-4" width="500px" src= "{% static 'images/logo_diatlas.png' %}" alt = "DIAtlas" />
                    <p class="lead">{% trans 'DIAtlas je aplikacija za vnos, urejanje, analiziranje in kartiranje dialektoloških podatkov.' %}</p>
                    <img class="mx-auto mt-5" width="200px" src= "{% static 'images/Logotip ZRC SAZU.png' %}" alt = "ZRC SAZU" />
                    <p class="kreon">{% trans ' Inštitut za slovenski jezik Frana Ramovša ' %}</p>
                    <img class="mx-auto" height="80px" src= "{% static 'images/aris-sl.png' %}" alt = "ARIS"/>
                    <p class="kreon">
                        Aplikacija je nastala v okviru programa Slovenski jezik v sinhronem in diahronem razvoju (P6-0038, 1. 1. 2022–31. 12. 2027) in projekta i-SLA – Interaktivni atlas slovenskih narečij (L6-2628, 1. 9. 2020–31. 8. 2024), ki ju sofinancira ARIS.
                    </p>
                    <img class="mx-auto" height="50px" src= "{% static 'images/rubin_logo_z_obrobo.jpg' %}" alt = "ARIS"/>
                    <p class="kreon">
                        &copy; {{ year }} - {% trans 'Rubin geoinformatika d.o.o.' %}
                    </p>
                    </div>
                </div>

-->
<style scoped>
    .main {
        padding: 20px;
        height: calc(100vh - 151px);
    }
    h5 {
        margin-top: 20px;
    }
    q-separator {
        margin-top: 10px;
    }
</style>

<script>
import { ref } from "vue";
import { useGettext } from "vue3-gettext";
import store from '../store';


export default {
    name: "About",
    data() {
        console.log("About data");
        return {
            language: useGettext(),
        };
    },
    //computed: {
    //    ...mapState(["language"]),
    //},
};


</script>